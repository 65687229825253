<template>
  <moe-page title="问卷列表">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <div class="df aic jcsb">
          <div class="df fdc">
            <div class="font-20 fwb">问卷调查</div>
          </div>
        </div>
      </template>

      <moe-inquire @search="surveySearch">
        <el-form-item label="状态">
          <moe-select type="surveyStateList" v-model="surveyParams.state" placeholder="选择状态"></moe-select>
        </el-form-item>
        <el-form-item label="问卷名称">
          <el-input v-model.trim="surveyParams.name" placeholder="输入问卷名称" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="使用时间" prop="startTime">
          <el-date-picker
            style="width: 100%;"
            placement="bottom-start"
            v-model="datetime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="(datetime) => ([surveyParams.startTime, surveyParams.endTime] = datetime || ['', ''])" />
        </el-form-item>
      </moe-inquire>

      <moe-table ref="surveyTableRef" url="/shop/survey/list" :params="surveyParams" :numberShow="false">
        <el-button slot="tool" type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/questionnaireSurvey/add')">新建问卷</el-button>
        <el-table-column label="问卷编号" prop="id" min-width="80" />
        <el-table-column label="问卷名称" prop="name" min-width="120" :show-overflow-tooltip="true" />
        <el-table-column label="活动时间" min-width="310">
          <div class="df fdc aic jcc" slot-scope="{ row }">
            <div>{{ $moe_time.getTimeStamp(row.startTime) }} 至 {{ $moe_time.getTimeStamp(row.endTime) }}</div>
            <div>活动持续：{{ $moe_time.countDown(row.endTime, row.startTime) }}</div>
          </div>
        </el-table-column>
        <el-table-column label="创建时间" min-width="150">
          <template slot-scope="{ row }">
            {{ $moe_time.getTimeStamp(row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column label="状态" min-width="150">
          <div :class="$moe_format.getAllStateListColor(row.state)" slot-scope="{ row }">
            {{ $moe_format.getSurveyStateList(row.state) }}
          </div>
        </el-table-column>
        <el-table-column label="参与人数" prop="joinCount" min-width="80" />
        <el-table-column label="操作" fixed="right" width="400">
          <div class="moe-table_btns" slot-scope="{ row }">
            <el-button type="success" size="mini" icon="el-icon-document" @click="$moe_coordinator.navigateTo(`/questionnaireSurvey/detail`, { id: row.id })">查看问卷</el-button>
            <template v-if="!['FINISHED'].includes(row.state)">
              <el-button type="primary" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/questionnaireSurvey/add`, { id: row.id })">编辑</el-button>
              <el-button type="danger" size="mini" icon="el-icon-circle-close" @click="handleClose(row)">关闭</el-button>
            </template>
            <el-button :disabled="row.joinCount === 0" type="success" size="mini" icon="el-icon-document" @click="$moe_coordinator.navigateTo(`/questionnaireSurvey/record`, { id: row.id })">查看结果</el-button>
          </div>
        </el-table-column>
      </moe-table>
    </moe-card>
  </moe-page>
</template>

<script>
export default {
  name: 'QuestionnaireSurveyList',
  data() {
    return {
      surveyParams: {
        pageNum: 1,
        pageSize: 10,
        name: '',
        startTime: '',
        endTime: '',
        state: '',
      },
      datetime: [],
    }
  },
  methods: {
    surveySearch(isSearch) {
      if (!isSearch) {
        this.surveyParams = {
          pageNum: 1,
          pageSize: 10,
          name: '',
          startTime: '',
          endTime: '',
          state: '',
        };

        this.datetime = [];
      }

      this.$refs['surveyTableRef'].searchData();
    },
    async handleClose({ name, id }) {
      this.$moe_layer.confirm(`您确定要关闭当前选项"${name}"吗？`, async () => {
        const { code, message } = await this.$moe_api.QUESTIONNAIRESURVEY_API.closeSurvey({ id });
        if (code === 200) {
          this.$moe_msg.success('关闭成功');
          this.surveySearch(false);
        } else {
          this.$moe_msg.error(message);
        }
      })
    },
  }
}
</script>